import { isApiError, APIError, APIErrorCode } from 'utils';
import { useIntakeCommonStore } from '../features/common';

type InternalError = Omit<APIError, 'code'>;
type NetworkError = InternalError | APIError;

const IntError = {
  message: 'Internal Service Error',
};

export const apiErrorToThrow = (error: any, setGlobalErrorState = true): NetworkError => {
  if (setGlobalErrorState) {
    useIntakeCommonStore.setState({ networkError: true });
  }
  console.error(`Top level catch block:\nError: ${error}\nError stringified: ${JSON.stringify(error)}`);
  if (isApiError(error)) {
    return error;
  } else {
    console.error('An endpoint threw and did not provide a well formed ApiError');
    return IntError;
  }
};

export type FormValidationErrorObject = { [paggeId: string]: string[] };
export const getFormValidationErrors = (error: any): FormValidationErrorObject | undefined => {
  if (isApiError(error)) {
    const apiError = error as APIError;
    if (apiError.code === APIErrorCode.QUESTIONNAIRE_RESPONSE_INVALID) {
      const errorJson = apiError.message.split(':')?.slice(1)?.join(':');
      console.log('errorJson', errorJson);
      if (errorJson) {
        try {
          return JSON.parse(errorJson) as FormValidationErrorObject;
        } catch (e) {
          console.error('could not parse validation error message', e);
        }
      }
    }
  }
  return undefined;
};
