import { useAuth0 } from '@auth0/auth0-react';
import { Box, List, ListItem, Typography, useTheme } from '@mui/material';
import { useLocation, useParams } from 'react-router-dom';
import { IntakeFlowPageRoute } from '../App';
import { otherColors } from '../IntakeThemeProvider';
import { UCContainer } from '../components';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { PageForm } from 'ui-components';
import { WaitingEstimateCard } from '../components/WaitingEstimateCard';
import { useTrackMixpanelEvents } from '../hooks/useTrackMixpanelEvents';
import { VisitType } from 'utils';
import { useBookingContext } from './Welcome';
import { useCheckOfficeOpen } from '../hooks/useCheckOfficeOpen';
import { usePreserveQueryParams } from '../hooks/usePreserveQueryParams';

const GetReadyForVisit = (): JSX.Element => {
  const theme = useTheme();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { isAuthenticated, loginWithRedirect } = useAuth0();
  const { slug: slugParam, visit_type: visitTypeParam, state: stateParam } = useParams();
  const visitType = visitTypeParam as VisitType;
  const waitingMinutes = location.state && parseInt(location.state.waitingTime);
  const { selectedLocation } = useBookingContext();
  const preserveQueryParams = usePreserveQueryParams();

  const { officeOpen } = useCheckOfficeOpen(selectedLocation);

  const onSubmit = async (): Promise<void> => {
    if (!isAuthenticated) {
      loginWithRedirect({
        appState: { target: preserveQueryParams(`/location/${stateParam}/${slugParam}/${visitType}/patients`) },
      }).catch((error) => {
        throw new Error(`Error calling loginWithRedirect Auth0: ${error}`);
      });
    } else {
      navigate(`/location/${stateParam}/${slugParam}/${visitType}/patients`);
    }
  };

  useTrackMixpanelEvents({
    eventName: 'Get Ready For Visit',
    visitType: visitType,
    bookingCity: selectedLocation?.address?.city,
    bookingState: selectedLocation?.address?.state,
  });

  const bgVariant = IntakeFlowPageRoute.WelcomeType.path;

  return (
    <UCContainer
      title={t('getReady.title')}
      imgWidth={150}
      bgVariant={bgVariant}
      topOutsideCardComponent={
        visitTypeParam === VisitType.PreBook && officeOpen ? (
          <WaitingEstimateCard waitingMinutes={waitingMinutes} />
        ) : undefined
      }
    >
      <>
        <Box
          sx={{
            backgroundColor: otherColors.lightPurpleAlt,
            color: theme.palette.secondary.main,
            padding: 2,
            marginBottom: 3,
            borderRadius: '8px',
          }}
        >
          <Typography variant="h3" color={theme.palette.primary.main}>
            {t('getReady.subtitle')}
          </Typography>
          <List sx={{ listStyleType: 'disc', marginLeft: 3 }}>
            <ListItem sx={{ display: 'list-item', paddingLeft: 0 }}>
              <Typography variant="body1">{t('getReady.body1')}</Typography>
            </ListItem>
            <ListItem sx={{ display: 'list-item', paddingLeft: 0 }}>
              <Typography variant="body1">{t('getReady.body2')}</Typography>
            </ListItem>
          </List>
        </Box>
        <Box>
          <Typography variant="body2" color={theme.palette.text.primary} marginTop={1}>
            {t('getReady.body3')}
          </Typography>
          <Typography variant="body2" color={theme.palette.text.primary} marginTop={1}>
            {t('getReady.body4')}
          </Typography>
        </Box>
      </>
      <PageForm onSubmit={onSubmit} controlButtons={{ backButton: false }} />
    </UCContainer>
  );
};

export default GetReadyForVisit;
