import { VisitType } from 'utils';
import { AvailableLocationInformation } from '../api/zapehrApi';

interface RedirectResult {
  relative?: string;
  absolute?: string;
}

export const getRedirectPath = (params: Record<string, string | undefined>, path: string): RedirectResult => {
  if (path === '/') {
    // console.log('handling base path');
    return { absolute: 'https://pmpediatriccare.com' };
  }
  const { slug: slugParam, visit_type: visitTypeParam } = params;
  if (!slugParam || !visitTypeParam) {
    return {};
  }
  const oldPathForm = `/location/${slugParam}/${visitTypeParam ?? 'prebook'}`;
  const newPathForm = `/location/ny/${slugParam}/${visitTypeParam ?? 'prebook'}`;
  if (path.startsWith(oldPathForm)) {
    return { relative: path.replace(oldPathForm, newPathForm) };
  }

  return {};
};

export const getStartingPath = (
  selectedLocation: AvailableLocationInformation | undefined,
  visitType: VisitType | undefined
): string => {
  const state = selectedLocation?.address?.state?.toLowerCase() || '';
  const slug = selectedLocation?.slug?.toLowerCase() || '';

  return `/location/${state}/${slug}/${visitType}`;
};
