import { isOlderThan18Years, isoStringFromDateComponents, VisitType } from 'utils';
import { getParentAndPatientOption, getParentOption } from './constants';
import { EmailUserValue } from '../types/types';
import { PatientInfoInProgress } from '../features/patients';
import { t } from 'i18next';
export const MINIMUM_AGE = 0;
export const MAXIMUM_AGE = 26;
export const emailRegex = /^\S+@\S+\.\S+$/;
export const zipRegex = /^\d{5}$/;
export const phoneRegex = /^\(\d{3}\) \d{3}-\d{4}$/;
export const emojiRegex = /^(?:(?!\p{Extended_Pictographic}).)*$/u;

interface AgeDependentConfiguration {
  defaultEmail: string;
  patientInformationRadioOptions: {
    label: string;
    value: string;
  }[];
  patientOver18?: boolean;
  fillingOutAs: EmailUserValue;
}

export const getPatientAgeDependentData = (
  formValues: any,
  unconfirmedDateOfBirth: string | undefined,
  formDob: string | undefined,
  formFillingOutAs: 'Parent/Guardian' | 'Patient (Self)' | undefined
): AgeDependentConfiguration => {
  const dateOfBirth = formDob ?? unconfirmedDateOfBirth;

  // console.log('form dob', formDob);

  const patientOver18 = dateOfBirth ? isOlderThan18Years(dateOfBirth) : false;

  const parentAndPatientOption = getParentAndPatientOption(t);
  const parentOption = getParentOption(t);

  let fillingOutAs = formFillingOutAs ?? 'Parent/Guardian';

  if (patientOver18) {
    if (!fillingOutAs) {
      fillingOutAs = 'Patient (Self)';
    }
    return {
      defaultEmail: formValues['patient-email'] ?? '',
      patientInformationRadioOptions: parentAndPatientOption,
      patientOver18,
      fillingOutAs,
    };
  }

  return {
    defaultEmail: fillingOutAs === 'Parent/Guardian' ? formValues['guardian-email'] : formValues['patient-email'],
    patientInformationRadioOptions: parentOption,
    patientOver18,
    fillingOutAs,
  };
};

const parentAndPatientOption = getParentAndPatientOption(t);
const parentOption = getParentOption(t);

export const getPatientAgeDependentDataWithPatientData = (
  patientInfo: PatientInfoInProgress | undefined,
  unconfirmedDateOfBirth: string | undefined,
  formDob: string | undefined,
  formFillingOutAs: 'Parent/Guardian' | 'Patient (Self)' | undefined,
  visitType: VisitType | undefined
): AgeDependentConfiguration => {
  let patientInfoDob: string | undefined;
  if (patientInfo?.dobDay && patientInfo?.dobMonth && patientInfo?.dobYear) {
    patientInfoDob = isoStringFromDateComponents({
      day: patientInfo?.dobDay,
      month: patientInfo?.dobMonth,
      year: patientInfo?.dobYear,
    });
  }
  const dateOfBirth = formDob ?? unconfirmedDateOfBirth ?? patientInfoDob;

  let fillingOutAs = formFillingOutAs;
  let patientOver18 = false;

  if (dateOfBirth) {
    patientOver18 = isOlderThan18Years(dateOfBirth);
    if (patientOver18) {
      if (!fillingOutAs) {
        fillingOutAs = patientInfo?.emailUser !== 'Parent/Guardian' ? 'Patient (Self)' : 'Parent/Guardian';
      }
    }
  }

  if (!fillingOutAs) {
    fillingOutAs =
      patientInfo?.emailUser != undefined && patientInfo?.emailUser !== 'Parent/Guardian'
        ? 'Patient (Self)'
        : 'Parent/Guardian';
  }

  const radioOptions = visitType === VisitType.PreBook && !patientOver18 ? parentOption : parentAndPatientOption;

  return {
    defaultEmail: patientInfo?.email ?? '',
    patientInformationRadioOptions: radioOptions,
    patientOver18,
    fillingOutAs,
  };
};
