import { Navigate, Outlet, useLocation, useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { Box, CircularProgress, Typography, Link as MUILink } from '@mui/material';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { create } from 'zustand';
import { ErrorDialog } from 'ui-components';
import {
  formatPhoneNumberDisplay,
  getSelectors,
  isApiError,
  APIError,
  NO_READ_ACCESS_TO_PATIENT_ERROR,
  uuidRegex,
  APPOINTMENT_NOT_FOUND_ERROR,
  PMQuestionnaireItem,
  UCGetPaperworkResponse,
  AppointmentSummary,
  PaperworkPatient,
  flattenPMQuestionnaireItems,
  QuestionnaireFormFields,
  pickFirstValueFromAnswerItem,
  findQuestionnaireResponseItemLinkId,
} from 'utils';
import { zapehrApi } from '../api';
import useAppointmentNotFoundInformation from '../helpers/information';
import { useTrackMixpanelEvents } from '../hooks/useTrackMixpanelEvents';
import mixpanel from 'mixpanel-browser';
import { UCContainer } from '../components';
import ConfirmDateOfBirthForm from '../components/ConfirmDateOfBirthForm';
import { useSetLastActiveTime } from '../hooks/useSetLastActiveTime';
import { useAuth0 } from '@auth0/auth0-react';
import { IntakeFlowPageRoute } from '../App';
import { persist } from 'zustand/middleware';
import { DateTime } from 'luxon';
import { ZambdaClient, useUCZambdaClient } from '../hooks/useUCZambdaClient';
import { useGetFullName } from '../hooks/useGetFullName';
import { usePreserveQueryParams } from '../hooks/usePreserveQueryParams';
import PaperworkGroup from '../features/paperwork/PaperworkGroup';
import api from '../api/zapehrApi';
import { QuestionnaireResponse, QuestionnaireResponseItem } from 'fhir/r4b';
import { t } from 'i18next';

enum ChallengeLoadingState {
  initial,
  loading,
  complete,
  failed,
}
enum AuthedLoadingState {
  initial,
  loading,
  complete,
  noReadAcces,
}

type PaperworkState = {
  updateTimestamp: number | undefined;
  authMethod: 'token' | 'challenge' | undefined;
  paperworkInProgress: { [pageId: string]: QuestionnaireFormFields };
  paperworkResponse: UCGetPaperworkResponse | undefined;
};

export interface PaperworkContext
  extends Omit<UCGetPaperworkResponse, 'patient' | 'appointment' | 'questionnaireResponse'> {
  paperwork: QuestionnaireResponseItem[];
  paperworkInProgress: { [pageId: string]: QuestionnaireFormFields };
  pageItems: PMQuestionnaireItem[];
  pages: PMQuestionnaireItem[];
  appointment: AppointmentSummary | undefined;
  patient: PaperworkPatient | undefined;
  questionnaireResponse: QuestionnaireResponse | undefined;
  saveButtonDisabled?: boolean;
  setSaveButtonDisabled: (newVal: boolean) => void;
  findAnswerWithLinkId: (linkId: string) => QuestionnaireResponseItem | undefined;
}

export const usePaperworkContext = (): PaperworkContext => {
  return useOutletContext<PaperworkContext>();
};

interface PaperworkStateActions {
  setResponse: (response: UCGetPaperworkResponse, method: 'challenge' | 'token') => void;
  saveProgress: (pageId: string, responses: any) => void;
  patchCompletedPaperwork: (QR: QuestionnaireResponse) => void;
  clear: () => void;
}

const PAPERWORK_STATE_INITIAL: PaperworkState = {
  authMethod: undefined,
  updateTimestamp: undefined,
  paperworkInProgress: {},
  paperworkResponse: undefined,
};

const usePaperworkStore = create<PaperworkState & PaperworkStateActions>()(
  persist(
    (set) => ({
      ...PAPERWORK_STATE_INITIAL,
      setResponse: (response: UCGetPaperworkResponse, method: 'challenge' | 'token') => {
        set((state) => {
          // console.log('response.paperwork', response.paperwork);
          // console.log('state.paperwork', state.paperwork);
          return {
            ...state,
            paperworkResponse: response,
            authMethod: method,
          };
        });
      },
      saveProgress: (pageId: string, responses: any) => {
        const updateDT = DateTime.now().toSeconds();
        set((state) => {
          const pIP = { ...(state.paperworkInProgress || {}) };
          pIP[pageId] = responses;
          return {
            ...state,
            updateTimestamp: updateDT,
            paperworkInProgress: pIP,
          };
        });
      },
      patchCompletedPaperwork: (QR: QuestionnaireResponse) => {
        const updateDT = DateTime.now().toSeconds();
        set((state) => ({
          ...state,
          updateTimestamp: updateDT,
          paperworkResponse: {
            ...(state.paperworkResponse || ({} as UCGetPaperworkResponse)),
            questionnaireResponse: QR,
          },
          paperworkInProgress: {},
        }));
      },
      clear: () => {
        set({
          ...PAPERWORK_STATE_INITIAL,
        });
      },
    }),
    { name: 'uc-intake-paperwork-store-0.1' }
  )
);

export const PaperworkHome: FC = () => {
  const [appointmentNotFound, setAppointmentNotFound] = useState<boolean>(false);
  const { isAuthenticated, isLoading: authIsLoading } = useAuth0();
  const { id: appointmentId } = useParams();
  const tokenfulZambdaClient = useUCZambdaClient({ tokenless: false });
  const { pathname } = useLocation();
  const [authedFetchState, setAuthedFetchState] = useState(AuthedLoadingState.initial);
  const [challengeFetchState, setChallengeFetchState] = useState(ChallengeLoadingState.initial);
  const [saveButtonDisabled, setSaveButtonDisabled] = useState(false);

  const {
    paperworkInProgress,
    authMethod,
    paperworkResponse,
    updateTimestamp,
    setResponse,
    clear: clearPaperworkState,
  } = getSelectors(usePaperworkStore, [
    'paperworkInProgress',
    'setResponse',
    'paperworkResponse',
    'clear',
    'updateTimestamp',
    'authMethod',
  ]);

  const { allItems, questionnaireResponse, appointment, patient } = useMemo(() => {
    if (paperworkResponse === undefined) {
      return {
        allItems: [] as PMQuestionnaireItem[],
        questionnaireResponse: undefined,
        appointment: undefined,
        patien: undefined,
      };
    } else {
      const { allItems, questionnaireResponse, appointment, patient } = paperworkResponse;
      return {
        allItems: allItems ?? [],
        questionnaireResponse,
        appointment,
        patient,
      };
    }
  }, [paperworkResponse]);

  useEffect(() => {
    if (!isAuthenticated && !authIsLoading && authMethod === 'token') {
      clearPaperworkState();
    }
  }, [authIsLoading, authMethod, authedFetchState, clearPaperworkState, isAuthenticated]);

  useEffect(() => {
    if (appointmentId && appointment?.id && appointmentId !== appointment?.id) {
      clearPaperworkState();
    }
  }, [appointment?.id, appointmentId, clearPaperworkState]);

  useEffect(() => {
    const fetchAuthedPaperwork = async (apptId: string, zambdaClient: ZambdaClient): Promise<void> => {
      try {
        setAuthedFetchState(AuthedLoadingState.loading);
        const paperworkResponse = await zapehrApi.getPaperwork(zambdaClient, {
          appointmentID: apptId,
        });
        setResponse(paperworkResponse, 'token');
        setAuthedFetchState(AuthedLoadingState.complete);
      } catch (e) {
        if (isApiError(e)) {
          const apiError = e as APIError;
          if (apiError.code === NO_READ_ACCESS_TO_PATIENT_ERROR.code) {
            setAuthedFetchState(AuthedLoadingState.noReadAcces);
          } else {
            setAuthedFetchState(AuthedLoadingState.complete);
          }
        }
      }
    };
    if (isAuthenticated && tokenfulZambdaClient && authedFetchState === AuthedLoadingState.initial && appointmentId) {
      void fetchAuthedPaperwork(appointmentId, tokenfulZambdaClient);
    }
  }, [isAuthenticated, authedFetchState, setResponse, tokenfulZambdaClient, setAuthedFetchState, appointmentId]);

  useEffect(() => {
    try {
      let errorMessage;
      if (!appointmentId) {
        errorMessage = `appointment ID missing`;
        setAppointmentNotFound(true);
      } else {
        const isUuid = appointmentId.match(uuidRegex);
        if (!isUuid) {
          errorMessage = `appointment ID is not a valid UUID`;
          setAppointmentNotFound(true);
        }
      }

      if (errorMessage) {
        throw new Error(`${errorMessage}. path: ${pathname}`);
      }
    } catch (error) {
      console.error(error);
    }
  }, [appointmentId, pathname]);

  useEffect(() => {
    if (appointmentId && appointment?.id && appointmentId !== appointment.id) {
      // console.log('clearing state');
      clearPaperworkState();
    }
  }, [appointmentId, appointment?.id, clearPaperworkState]);

  const selectedLocation = useMemo(() => {
    return appointment?.location;
  }, [appointment?.location]);
  const phoneNumber = useMemo(() => {
    return selectedLocation?.telecom?.find((telecom) => telecom.system === 'phone')?.value;
  }, [selectedLocation?.telecom]);

  const completedPaperwork: QuestionnaireResponseItem[] = useMemo(() => {
    return questionnaireResponse?.item ?? [];
  }, [questionnaireResponse?.item]);

  const pages = useMemo(() => {
    return (allItems ?? []).filter((item) => {
      return item.linkId;
    });
  }, [allItems]);

  // console.log('completed paperwork', completedPaperwork);
  // console.log('allItems', allItems);
  // console.log('questions', questions);

  const outletContext: PaperworkContext = useMemo(() => {
    return {
      appointment,
      paperwork: completedPaperwork,
      paperworkInProgress,
      pageItems: allItems,
      allItems: flattenPMQuestionnaireItems(allItems ?? []),
      pages,
      questionnaireResponse,
      patient,
      updateTimestamp,
      saveButtonDisabled,
      setSaveButtonDisabled,
      findAnswerWithLinkId: (linkId: string): QuestionnaireResponseItem | undefined => {
        return findQuestionnaireResponseItemLinkId(linkId, completedPaperwork);
      },
    };
  }, [
    appointment,
    completedPaperwork,
    paperworkInProgress,
    allItems,
    pages,
    questionnaireResponse,
    patient,
    updateTimestamp,
    saveButtonDisabled,
  ]);

  const renderChallenge = useMemo(() => {
    const pathOne = !isAuthenticated && challengeFetchState !== ChallengeLoadingState.complete;

    const pathTwo =
      (isAuthenticated || authIsLoading) &&
      challengeFetchState !== ChallengeLoadingState.complete &&
      authedFetchState === AuthedLoadingState.noReadAcces;
    // console.log('path1', pathOne);
    // console.log('part2', pathTwo);
    // console.log('path2', pathTwo);
    return pathOne || pathTwo;
  }, [authIsLoading, authedFetchState, challengeFetchState, isAuthenticated]);

  const redirectTarget = useMemo(() => {
    if (
      pages &&
      pages.length &&
      (authedFetchState === AuthedLoadingState.complete || challengeFetchState === ChallengeLoadingState.complete)
    ) {
      const firstPage = pages[0].linkId.replace('-page', '');
      if (pathname === `/paperwork/${appointmentId}`) {
        return `/paperwork/${appointmentId}/${firstPage}`;
      }
    }
    return undefined;
  }, [appointmentId, authedFetchState, challengeFetchState, pathname, pages]);

  const appointmentNotFoundInformation = useAppointmentNotFoundInformation();

  if (appointmentNotFound) {
    return (
      <UCContainer
        title={t('paperwork.errors.notFound')}
        description={appointmentNotFoundInformation}
        bgVariant={IntakeFlowPageRoute.PaperworkHomeRoute.path}
      >
        <></>
      </UCContainer>
    );
  }

  if (
    (isAuthenticated || authIsLoading) &&
    (authedFetchState === AuthedLoadingState.initial || authedFetchState === AuthedLoadingState.loading)
  ) {
    return (
      <UCContainer title={t('paperwork.loading')} bgVariant={IntakeFlowPageRoute.PaperworkHomeRoute.path}>
        <Box sx={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <CircularProgress />
        </Box>
      </UCContainer>
    );
  }
  if (redirectTarget) {
    // console.log('redirecting...', redirectTarget);
    return <Navigate to={redirectTarget} replace={true} />;
  }
  if (renderChallenge) {
    return (
      <DOBChallengeSubcomponent
        phoneNumber={phoneNumber}
        patientInfo={patient}
        setAppointmentNotFound={setAppointmentNotFound}
        challengeFetchState={challengeFetchState}
        setChallengeFetchState={setChallengeFetchState}
        setResponse={setResponse}
        authedRequestIsLoading={
          authedFetchState === AuthedLoadingState.loading ||
          (isAuthenticated && authedFetchState === AuthedLoadingState.initial)
        }
      />
    );
  } else {
    return <Outlet context={{ ...outletContext }} />;
  }
};

interface DOBChallengeSubcomponentProps {
  phoneNumber: string | undefined;
  patientInfo: PaperworkPatient | undefined;
  authedRequestIsLoading: boolean;
  challengeFetchState: ChallengeLoadingState;
  setChallengeFetchState: (cfs: ChallengeLoadingState) => void;
  setAppointmentNotFound: (notFound: boolean) => void;
  setResponse: (response: UCGetPaperworkResponse, method: 'challenge' | 'token') => void;
}
const DOBChallengeSubcomponent: FC<DOBChallengeSubcomponentProps> = ({
  phoneNumber,
  authedRequestIsLoading,
  challengeFetchState,
  patientInfo,
  setChallengeFetchState,
  setAppointmentNotFound,
  setResponse,
}) => {
  const { id: appointmentID } = useParams();
  const tokenlessZambdaClient = useUCZambdaClient({ tokenless: true });
  const { isLoading: authLoading } = useAuth0();
  const preserveQueryParams = usePreserveQueryParams();

  const { loginWithRedirect } = useAuth0();

  const fetchPaperworkWithChallenge = useCallback(
    async (dateOfBirth: string): Promise<void> => {
      if (!appointmentID || !tokenlessZambdaClient) {
        return;
      }
      try {
        setChallengeFetchState(ChallengeLoadingState.loading);
        const paperworkResponse = await zapehrApi.getPaperwork(tokenlessZambdaClient, {
          appointmentID,
          dateOfBirth,
        });
        setResponse(paperworkResponse, 'challenge');
        setChallengeFetchState(ChallengeLoadingState.complete);
      } catch (e) {
        const error = e as any;
        if ((error as APIError)?.code === APPOINTMENT_NOT_FOUND_ERROR.code) {
          setAppointmentNotFound(true);
        }
        // todo: error handling
        console.log('error fetching challenge', e);
        setChallengeFetchState(ChallengeLoadingState.failed);
      }
    },
    [appointmentID, setAppointmentNotFound, setChallengeFetchState, setResponse, tokenlessZambdaClient]
  );

  const isLoading = useMemo(() => {
    return challengeFetchState === ChallengeLoadingState.loading || authLoading || authedRequestIsLoading;
  }, [challengeFetchState, authLoading, authedRequestIsLoading]);

  // console.log('isLoading', isLoading, challengeFetchState, authedRequestIsLoading, isAuthenticated);

  const loginToPaperwork = (): void => {
    loginWithRedirect({
      redirectUri: preserveQueryParams(`${window.location.origin}/redirect`),
      appState: {
        target: `/paperwork/${appointmentID}`,
      },
    }).catch((error) => {
      throw new Error(`Error calling loginWithRedirect Auth0: ${error}`);
    });
  };

  return (
    <UCContainer
      title={
        isLoading
          ? t('paperwork.loading')
          : `${t('paperwork.confirmPatient.confirm')} ${
              patientInfo?.firstName
                ? `${patientInfo?.firstName}${t('paperwork.confirmPatient.knownPatient')}`
                : t('paperwork.confirmPatient.unknownPatient')
            } ${t('paperwork.confirmPatient.dob')}`
      }
      bgVariant={IntakeFlowPageRoute.PaperworkHomeRoute.path}
    >
      {isLoading ? (
        <Box sx={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <CircularProgress />
        </Box>
      ) : (
        <ConfirmDateOfBirthForm
          patientInfo={undefined}
          description={
            <Typography variant="body1">
              {t('paperwork.logInToAccount.or')}{' '}
              <MUILink onClick={loginToPaperwork} sx={{ cursor: 'pointer' }}>
                {t('paperwork.logInToAccount.logIn')}
              </MUILink>{' '}
              {t('paperwork.logInToAccount.toAccount')}
            </Typography>
          }
          required={true}
          loading={challengeFetchState === ChallengeLoadingState.loading}
          dobConfirmed={challengeFetchState === ChallengeLoadingState.failed ? false : undefined}
          onConfirmedSubmit={async (confirmedDateOfBirth: string): Promise<void> => {
            await fetchPaperworkWithChallenge(confirmedDateOfBirth);
          }}
          wrongDateOfBirthModal={{
            buttonText: 'Log in',
            message: (
              <>
                <Typography marginTop={2}>{t('paperwork.errors.wrongDob.wrongDob1')}</Typography>
                {phoneNumber && (
                  <Typography marginTop={2}>
                    {t('paperwork.errors.wrongDob.wrongDob2')} {formatPhoneNumberDisplay(phoneNumber)}{' '}
                    {t('paperwork.errors.wrongDob.wrongDob3')}
                  </Typography>
                )}
              </>
            ),
            onSubmit: loginToPaperwork,
          }}
        />
      )}
    </UCContainer>
  );
};

export const PaperworkPage: FC = () => {
  // const theme = useTheme();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id: appointmentID, slug } = useParams();
  const tokenlessZambdaClient = useUCZambdaClient({ tokenless: true });
  const [fileErrorDialogOpen, setFileErrorDialogOpen] = useState<boolean>(false);
  const patchCompletedPaperwork = usePaperworkStore((state) => {
    return state.patchCompletedPaperwork;
  });
  const saveProgress = usePaperworkStore((state) => {
    return state.saveProgress;
  });
  const {
    appointment: appointmentData,
    paperwork: completedPaperwork,
    paperworkInProgress,
    pages: paperworkPages,
    patient: paperworkPatient,
    findAnswerWithLinkId,
  } = usePaperworkContext();
  const selectedLocation = useMemo(() => {
    return appointmentData?.location;
  }, [appointmentData?.location]);

  // this is just for avoiding duplicates in the mixpanel tracking
  const [lastLoggedPageName, setLastLoggedPageName] = useState<string>();

  const patientFullName = useGetFullName(paperworkPatient);

  const { nextPage, pageName, currentPage, currentIndex, empty, questionnaireItems } = useMemo(() => {
    const empty = {
      items: [],
      nextPage: undefined,
      pageName: undefined,
      currentPage: undefined,
      currentIndex: undefined,
      empty: true,
      questionnaireItems: [],
    };
    if (!paperworkPages) {
      // throw new Error('paperworkQuestions is not defined');
      return empty;
    }
    console.log('current slug', slug);
    const currentPage = paperworkPages.find((pageTemp) => slugFromLinkId(pageTemp.linkId) === slug);
    console.log('current page', currentPage);
    if (!currentPage) {
      // throw new Error('currentPage is not defined'); // todo: some better way of erroring here
      return empty;
    }

    const pageNameInput = currentPage.text;
    const pageName = t(`paperwork.pageNames.${pageNameInput}`);
    const questionnaireItems = currentPage.item ?? [];
    const currentIndex = paperworkPages.findIndex((pageTemp) => pageTemp.linkId === currentPage.linkId);
    const nextPage = paperworkPages[currentIndex + 1];
    return { nextPage, pageName, currentPage, currentIndex, empty: false, questionnaireItems };
  }, [paperworkPages, slug, t]);

  useEffect(() => {
    if (pageName !== lastLoggedPageName) {
      setLastLoggedPageName(pageName);
    }
  }, [lastLoggedPageName, pageName]);

  // todo: use or delete
  const [loading, setLoading] = useState<boolean>(false);

  // Track paperwork in Mixpanel
  const contactInfoPageName = 'Contact information';
  useTrackMixpanelEvents({
    eventName: lastLoggedPageName || '',
    visitType: appointmentData?.visitType,
    loading: lastLoggedPageName !== pageName,
    bookingCity: selectedLocation?.address?.city,
    bookingState: selectedLocation?.address?.state,
    mobileOptIn: pickFirstValueFromAnswerItem(findAnswerWithLinkId('mobile-opt-in'), 'boolean') ?? false,
  });

  // Update last active time for paperwork-in-progress flag every minute
  useSetLastActiveTime(appointmentID, !!slug, tokenlessZambdaClient);

  const controlButtons = useMemo(
    () => ({
      backButton: currentIndex !== 0,
      loading: loading,
      onBack: () => {
        navigate(-1);
      },
    }),
    [currentIndex, loading, navigate]
  );

  const paperworkGroupDefaults = useMemo(() => {
    const currentPageFields = (currentPage?.item ?? []).reduce((accum, item) => {
      if (item.type !== 'display') {
        accum[item.linkId] = { linkId: item.linkId };
      }
      return accum;
    }, {} as any);
    const currentPageEntries = completedPaperwork.find((item) => item.linkId === currentPage?.linkId)?.item;
    const inProgress = paperworkInProgress[currentPage?.linkId ?? ''] ?? {};
    if (!currentPageEntries && !inProgress) {
      return { ...currentPageFields };
    }
    // console.log('current page entries', currentPageEntries);
    const pageDefaults = (currentPageEntries ?? []).reduce((accum, entry) => {
      accum[entry.linkId] = { ...entry };
      return accum;
    }, {} as QuestionnaireFormFields);

    // console.log('in progress stuff', inProgress);
    // console.log('in progress page defaults', pageDefaults);
    return { ...currentPageFields, ...pageDefaults, ...inProgress };
  }, [completedPaperwork, currentPage, paperworkInProgress]);

  // console.log('completed', completedPaperwork);
  // console.log('paperworkGroupDefaults', paperworkGroupDefaults);

  const finishPaperworkPage = useCallback(
    async (data: QuestionnaireFormFields): Promise<void> => {
      // Track Mixpanel user profile properties
      // todo: move this?
      if (lastLoggedPageName === contactInfoPageName) {
        mixpanel.people.set({
          Address: pickFirstValueFromAnswerItem(data['patient-street-address']),
          Apartment: pickFirstValueFromAnswerItem(data['patient-street-address-2']),
          'Contact City': pickFirstValueFromAnswerItem(data['patient-city']),
          State: pickFirstValueFromAnswerItem(data['patient-state']),
          Zip: pickFirstValueFromAnswerItem(data['patient-zip']),
          Marketing: pickFirstValueFromAnswerItem(data['mobile-opt-in'], 'boolean'),
        });
      }
      if (lastLoggedPageName === 'How would you like to pay for your visit?') {
        pickFirstValueFromAnswerItem(data['payment-option']) === 'I will pay without insurance'
          ? mixpanel.people.set({ 'Insurance Type': 'Self Pay' })
          : mixpanel.people.set({ 'Insurance Type': pickFirstValueFromAnswerItem(data['insurance-carrier']) });
      }
      if (lastLoggedPageName === 'Responsible party information') {
        mixpanel.people.set({
          $first_name: pickFirstValueFromAnswerItem(data['responsible-party-first-name']),
          $last_name: pickFirstValueFromAnswerItem(data['responsible-party-last-name']),
        });
      }
      const patchPaperwork = async (
        data: QuestionnaireResponseItem[],
        aptId: string,
        pageId: string,
        zambdaClient: ZambdaClient
      ): Promise<QuestionnaireResponse> => {
        return api.patchPaperwork(zambdaClient, {
          answers: { linkId: pageId, item: data },
          appointmentId: aptId,
        });
      };

      if (data && appointmentID && tokenlessZambdaClient && currentPage) {
        const raw = (Object.values(data) ?? []) as QuestionnaireResponseItem[];
        const responseItems = raw
          .filter((item) => {
            if (item.linkId === undefined || (item.answer === undefined && item.item === undefined)) {
              return false;
            }
            return true;
          })
          .map((item) => {
            // for some reason group items are getting "answer: [null]""
            if (item.answer && item.answer[0] == undefined) {
              return { ...item, answer: undefined };
            }
            return item;
          });
        // console.log('responseItems', responseItems, data);
        try {
          setLoading(true);
          const updatedPaperwork = await patchPaperwork(
            responseItems,
            appointmentID,
            currentPage.linkId,
            tokenlessZambdaClient
          );
          patchCompletedPaperwork(updatedPaperwork);
          saveProgress(currentPage.linkId, undefined);
          navigate(
            `/paperwork/${appointmentID}/${nextPage !== undefined ? slugFromLinkId(nextPage.linkId) : 'review'}`
          );
        } catch (e) {
          // todo: handle this better
          console.error('error patching paperwork', e);
        } finally {
          setLoading(false);
        }
      }
    },
    [
      lastLoggedPageName,
      appointmentID,
      tokenlessZambdaClient,
      currentPage,
      patchCompletedPaperwork,
      saveProgress,
      navigate,
      nextPage,
    ]
  );

  // console.log('questionnaireItems', questionnaireItems);

  return (
    <UCContainer
      title={pageName ?? ''}
      patientFullName={patientFullName}
      bgVariant={slugFromLinkId(currentPage?.linkId ?? '')}
    >
      {empty ? (
        <Box sx={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <CircularProgress />
        </Box> // can we do something better than an infinite loader here
      ) : (
        <>
          <PaperworkGroup
            onSubmit={finishPaperworkPage}
            pageId={currentPage?.linkId ?? ''}
            options={{ controlButtons }}
            items={questionnaireItems}
            defaultValues={paperworkGroupDefaults}
            saveProgress={(data) => {
              const pageId = currentPage?.linkId;
              if (pageId) {
                saveProgress(pageId, data);
              }
            }}
          />
          <ErrorDialog
            open={fileErrorDialogOpen}
            title={t('paperwork.errors.file.title')}
            description={t('paperwork.errors.file.description')}
            closeButtonText={t('paperwork.errors.file.close')}
            handleClose={() => setFileErrorDialogOpen(false)}
          />
        </>
      )}
    </UCContainer>
  );
};

export const slugFromLinkId = (linkId: string): string => {
  return linkId.replace('-page', '');
};
