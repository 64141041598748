import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { Box, Typography } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import QuestionMarkButton from './QuestionMarkButton';
import { useLocation } from 'react-router-dom';
import { useBookingContext } from '../pages/Welcome';

const Footer: FC = () => {
  const { t } = useTranslation();
  const location = useLocation();

  const { selectedLocation, visitType } = useBookingContext();

  // only show on the review page
  const reviewPgPath = `/location/${selectedLocation?.address?.state?.toLocaleLowerCase()}/${selectedLocation?.slug}/${visitType}/review`;
  const showFooter = location.pathname === reviewPgPath;

  return (
    <Box sx={{ position: 'sticky', bottom: 0, pointerEvents: 'none' }}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          padding: '16px',
          pointerEvents: 'all',
          marginLeft: 'auto',
          width: 'fit-content',
        }}
      >
        <QuestionMarkButton
          url={
            selectedLocation?.telecom?.find((item) => item.system === 'url')?.value ||
            'https://pmpediatriccare.com/contact-us/'
          }
        />
      </Box>
      {showFooter && (
        <Box
          sx={{
            width: '100%',
            backgroundColor: 'secondary.main',
            bottom: 0,
          }}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <ErrorOutlineIcon color="warning" sx={{ pl: 1.25, marginTop: '10px', marginBottom: 'auto' }} />
          {/* Max width chosen to have text evenly spread across 2 lines
            https://github.com/masslight/pmp-behavioral-health-intake-ui/issues/70#issuecomment-1378777153 */}
          <Typography variant="body2" color="primary.contrast" sx={{ m: 1.25, maxWidth: 850 }}>
            {t('general.footer')}
          </Typography>
        </Box>
      )}
    </Box>
  );
};
export default Footer;
