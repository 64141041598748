import { Patient, Appointment } from 'fhir/r4b';

export enum PMP_MODULE {
  UC = 'PMP-UC',
  BH = 'PMP-BH',
  TM = 'PMP-TM',
}

export const isUrgentCareAppointment = (appointment: Appointment): boolean => {
  const tags = appointment.meta?.tag ?? [];

  return tags.some((tag) => {
    return tag.code === PMP_MODULE.UC;
  });
};

export const isBHResource = (resource: Appointment | Patient): boolean => {
  const tags = resource.meta?.tag ?? [];

  return tags.some((tag) => {
    return tag.code === PMP_MODULE.BH;
  });
};
