import { bg1, bg2, bg3, pmLogo as logo } from '../assets';
import Footer from './Footer';
import { IntakeFlowPageRoute } from '../App';
import { ContainerProps, CustomContainer } from 'ui-components';
import { useCallback } from 'react';
import { useClearStores } from '../features';
import { useAuth0 } from '@auth0/auth0-react';
import { useIntakeCommonStore } from '../features/common';
import React from 'react';
import mixpanel from 'mixpanel-browser';

const imageForBackground = (page: string): string => {
  switch (page) {
    case IntakeFlowPageRoute.Welcome.path:
    case IntakeFlowPageRoute.WelcomeBack.path:
    case IntakeFlowPageRoute.ConfirmDateOfBirth.path:
    case IntakeFlowPageRoute.NewUser.path:
    case IntakeFlowPageRoute.Appointments.path:
    case IntakeFlowPageRoute.PaperworkHomeRoute.path:
      return bg1;
    case IntakeFlowPageRoute.PatientInformation.path:
    case 'contact-information':
    case 'patient-details':
    case 'payment-option':
    case 'responsible-party':
    case 'photo-id':
    case 'consent-forms':
    case IntakeFlowPageRoute.Review.path:
    case IntakeFlowPageRoute.ReviewPaperwork.path:
      return bg2;
    case IntakeFlowPageRoute.CheckIn.path:
    case IntakeFlowPageRoute.ThankYou.path:
    case IntakeFlowPageRoute.WelcomeType.path:
    case IntakeFlowPageRoute.CancellationConfirmation.path:
    case IntakeFlowPageRoute.CancellationReason.path:
      return bg3;
    default:
      return bg1;
  }
};

type UCContainerProps = Omit<
  ContainerProps,
  'logo' | 'backgroundImage' | 'showLanguagePicker' | 'footer' | 'logoutHandler' | 'alt'
>;

export const UCContainer: React.FC<UCContainerProps> = (props) => {
  const lastUsedLocationPath = useIntakeCommonStore((state) => state.lastUsedLocationPath);
  const { logout } = useAuth0();

  const clearStore = useClearStores();

  const logoutHandler = useCallback(async () => {
    clearStore(lastUsedLocationPath);
    mixpanel.reset();
    // for some reason this is necessary to get auth0 to clear out its local state
    logout({ returnTo: 'https://pmpediatriccare.com' });
    logout({ localOnly: true });
  }, [clearStore, lastUsedLocationPath, logout]);
  const passThroughProps = {
    ...props,
    logoutHandler,
    footer: <Footer />,
    logo,
    alt: 'PM Pediatrics Urgent Care',
    backgroundImage: imageForBackground(props.bgVariant),
  };
  return <CustomContainer showLanguagePicker={true} {...passThroughProps} />;
};
