import { PatientBaseInfo } from 'utils';
import { useMemo } from 'react';

export const useGetFullName = (patient: PatientBaseInfo | undefined): string | undefined => {
  return useMemo(() => {
    if (patient) {
      const { firstName, middleName, lastName } = patient;
      return `${firstName}${middleName ? ` ${middleName}` : ''} ${lastName}`;
    }
    return undefined;
  }, [patient]);
};
